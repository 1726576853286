import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeaderSection from '../components/sections/HeaderSection';
import Helmet from 'react-helmet';

function Privacy() {
  function gaSet() {
    return typeof window !== 'undefined' && document.cookie.indexOf(disableStr) >= 0;
  }

  return (
    <Layout>
      <SEO title="Datenschutzerklärung" keywords={[]}/>
      <Helmet>
        <script>{`
      // Opt-out function
      function gaOptout() {
        document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[disableStr] = true;
      }
  `}</script>
      </Helmet>
      <HeaderSection>
        <h1>
          Datenschutzerklärung
        </h1>
      </HeaderSection>
      <div className="flex flex-col text-justify">
        <h3 className="my-4">§ 1 Allgemeines</h3>
        <p>
          Ihre personenbezogenen Daten (z.B. Anrede, Name, Anschrift, E-Mail-Adresse, Telefonnummer) werden von uns nur
          gemäß den Bestimmungen des deutschen Datenschutzrechts und des Datenschutzrechts der Europäischen Union (EU)
          verarbeitet. Die nachfolgenden Vorschriften informieren Sie neben den Verarbeitungszwecken, Rechtsgrundlagen,
          Empfängern, Speicherfristen auch über Ihre Rechte und den Verantwortlichen für Ihre Datenverarbeitung. Diese
          Datenschutzerklärung bezieht sich nur auf unsere Websites. Falls Sie über Links auf unseren Seiten auf andere
          Seiten weitergeleitet werden, informieren Sie sich bitte dort über den jeweiligen Umgang mit Ihren Daten.
        </p>
        <h3 className="my-4">§ 2 Kontaktaufnahme</h3>
        <div>
          <h4 className="my-2">(1) Verarbeitungszweck</h4>
          <p>
            Ihre personenbezogenen Daten, die Sie uns per E-Mail, Kontaktformular etc. zur Verfügung stellen,
            verarbeiten wir zur Beantwortung und Erledigung Ihrer Anfragen. Sie sind nicht verpflichtet, uns Ihre
            personenbezogenen Daten bereitzustellen. Aber ohne Mitteilung Ihrer E-Mail Adresse können wir Ihnen auch
            nicht per E-Mail antworten.
          </p>
          <h4 className="my-2">(2) Rechtsgrundlagen</h4>
          <p>
            a) Sollten Sie uns eine ausdrückliche Einwilligung zur Verarbeitung Ihrer Daten gegeben haben, ist Art.6
            Abs. 1a) DSGVO die Rechtsgrundlage für diese Verarbeitung.
          </p>
          <p>
            b) Sollten wir Ihre Daten zur Durchführung vorvertraglicher Maßnahmen verarbeiten, ist Art.6 Abs. 1b) DSGVO
            die Rechtsgrundlage.
          </p>
          <p>
            c) In allen anderen Fällen (insbesondere bei Nutzung eines Kontaktformulars) ist Art.6 Abs. 1f) DSGVO die
            Rechtsgrundlage.
          </p>
          <p className="font-semibold my-4">
            WIDERSPRUCHSRECHT: Sie haben das Recht, der Datenverarbeitung die auf der Grundlage des Art. 6 Abs. 1 f)
            DSGVO erfolgt und nicht der Direktwerbung dient aus Gründen, die sich aus Ihrer besonderen Situation
            ergeben, jederzeit zu widersprechen.
          </p>
          <p className="font-semibold my-4">
            Im Falle der Direktwerbung können Sie der Verarbeitung hingegen ohne Angabe von Gründen jederzeit
            widersprechen.
          </p>
          <h4 className="my-2">(3) Berechtigtes Interesse</h4>
          <p>
            Unser berechtigtes Interesse an der Verarbeitung besteht darin, mit Ihnen auf schnellem Wege zu
            kommunizieren und Ihre Anfragen kostengünstig zu beantworten. Wenn Sie uns Ihre Anschrift mitteilen,
            behalten wir uns vor, diese für postalische Direktwerbung zu verwenden. Ihr Interesse am Datenschutz können
            Sie durch eine sparsame Datenweitergabe (z.B. Verwendung eines Pseudonyms) wahren.
          </p>
          <h4 className="my-2">(4) Empfängerkategorien</h4>
          <p>
            Hostinganbieter, Versanddienstleister bei Direktwerbung
          </p>
          <h4 className="my-2">(5) Speicherdauer</h4>
          <p>
            Ihre Daten werden gelöscht, wenn sich aus den Umständen entnehmen lässt, dass Ihre Anfrage bzw. der
            betroffene Sachverhalt abschließend geklärt ist.
          </p>
          <p>
            Falls es jedoch zu einem Vertragsschluss kommt, werden die nach Handels- und Steuerrecht erforderlichen
            Daten von uns für die gesetzlich bestimmten Zeiträume aufbewahrt, also regelmäßig zehn Jahre (vgl. §257 HGB,
            §147 AO).
          </p>
          <h4 className="my-2">(6) Widerrufsrecht</h4>
          <p>
            Sie haben im Fall der Verarbeitung aufgrund Ihrer Einwilligung das Recht, Ihre Einwilligung jederzeit zu
            widerrufen.
          </p>
        </div>
        <h3 className="my-4">§ 3 Webanalyse mit Google Analytics</h3>
        <div>
          <h4 className="my-2">(1) Verarbeitungszweck</h4>
          <p>
            Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA. ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die auf
            Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
            durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen
            Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
            auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen
            Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
            in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu
            erbringen.
          </p>
          <h4 className="my-2">(2) Rechtsgrundlage</h4>
          <p>
            Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs.1 f) DSGVO.
          </p>
          <h4 className="my-2">(3) Berechtigtes Interesse</h4>
          <p>
            Unser berechtigtes Interesse ist die statistische Analyse des Nutzerverhaltens zu Optimierungs- und
            Marketingzwecken. Zum Schutz Ihres Interesses am Datenschutz benutzt diese Webseite Google Analytics mit der
            Erweiterung "anonymizeIP()", damit die IP-Adressen nur gekürzt weiterverarbeitet werden, um eine direkte
            Personenbeziehbarkeit auszuschließen.
          </p>
          <h4 className="my-2">(4) Empfängerkategorien</h4>
          <p>
            Google, Partnerunternehmen
          </p>
          <h4 className="my-2">(5) Übermittlung an ein Drittland</h4>
          <p>
            Google LLC mit Sitz in den USA ist für das us-europäische Datenschutzübereinkommen „Privacy Shield“
            zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.
          </p>
          <h4 className="my-2">(6) Speicherdauer</h4>
          <p>
            Unbegrenzt
          </p>
          <h4 className="my-2">(7) WIDERSPRUCHSRECHT</h4>
          <p className="font-semibold">
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
            durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
            Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
            verfügbare Browser-Plugin herunterladen und installieren:
            {' '}
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer" target="_blank">optout</a>
          </p>
          <p className="font-semibold">
            Sie können die Erfassung auch durch das Setzen eines Opt-Out-Cookies unterbinden. Wenn Sie die zukünftige
            Erfassung Ihrer Daten beim Besuch dieser Website verhindern möchten, klicken Sie bitte hier:
            {' '}
            <a href="javascript:gaOptout()" rel="noreferrer noopener" target="privacy">Google Analytics deaktivieren</a>
          </p>
        </div>
        <h3 className="my-4">§ 4 Rechte der betroffenen Person</h3>
        <div>
          <p>
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen
            folgende Rechte uns gegenüber zu:
          </p>
          <h4 className="my-2">1. Auskunftsrecht</h4>

          <p>
            Sie können von uns eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
            verarbeitet werden.
          </p>
          <p>
            Liegt eine solche Verarbeitung vor, können Sie von uns über folgende Informationen Auskunft verlangen:
          </p>
          <p>
            (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
          </p>
          <p>
            (2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
          </p>
          <p>
            (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen
            Daten offengelegt wurden oder noch offengelegt werden;
          </p>
          <p>
            (4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete
            Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
          </p>
          <p>
            (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten,
            eines Rechts auf Einschränkung der Verarbeitung durch uns oder eines Widerspruchsrechts gegen diese
            Verarbeitung;
          </p>
          <p>
            (6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
          </p>
          <p>
            (7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei
            der betroffenen Person erhoben werden;
          </p>
          <p>
            (8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art.22 Abs.1 und
            4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie
            die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
          </p>
          <p>
            Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in
            ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie
            verlangen, über die geeigneten Garantien gem. Art.46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet
            zu werden.
          </p>
          <h4 className="my-2">2. Recht auf Berichtigung</h4>

          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung uns gegenüber, sofern die verarbeiteten
            personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Wir müssen die Berichtigung
            unverzüglich vornehmen.
          </p>
          <h4 className="my-2">3. Recht auf Einschränkung der Verarbeitung</h4>

          <p>
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden
            personenbezogenen Daten verlangen:
          </p>
          <p>
            (1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten für eine Dauer bestreiten, die es
            uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
          </p>
          <p>
            (2) wenn die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
            stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
          </p>
          <p>
            (3) wenn wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie diese
            jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
          </p>
          <p>
            (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art.21 Abs.1 DSGVO eingelegt haben und noch nicht
            feststeht, ob die uns zustehenden berechtigten Gründe gegenüber Ihren Gründen überwiegen.
          </p>
          <p>
            Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von
            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <p>
            Wurde die Einschränkung der Verarbeitung nach den oben genannten Voraussetzungen eingeschränkt, werden Sie
            von uns unterrichtet bevor die Einschränkung aufgehoben wird.
          </p>
          <h4 className="my-2">4. Recht auf Löschung</h4>

          <p>
            a) Löschungspflicht
          </p>
          <p>
            Sie können von uns verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht
            werden, und wir sind verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe
            zutrifft:
          </p>
          <p>
            (1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige
            Weise verarbeitet wurden, nicht mehr notwendig.
          </p>
          <p>
            (2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art.6 Abs.1 lit.a oder Art.9 Abs.2
            lit.a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
          </p>
          <p>
            (3) Sie legen gem. Art.21 Abs.1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen
            berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art.21 Abs.2 DSGVO Widerspruch gegen die
            Verarbeitung ein.
          </p>
          <p>
            (4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
          </p>
          <p>
            (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
            Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
          </p>
          <p>
            (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
            Informationsgesellschaft gemäß Art.8 Abs.1 DSGVO erhoben.
          </p>
          <p>
            b) Information an Dritte
          </p>
          <p>
            Haben wir die Sie betreffenden personenbezogenen Daten öffentlich gemacht und sind wir gem. Art.17 Abs.1
            DSGVO zu deren Löschung verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und
            der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um die für die Datenverarbeitung
            Verantwortlichen, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als
            betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder
            Replikationen dieser personenbezogenen Daten verlangt haben.
          </p>
          <p>
            c) Ausnahmen
          </p>
          <p>
            Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
          </p>
          <p>
            (1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
          </p>
          <p>
            (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der
            Mitgliedstaaten, dem wir unterliegen, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen
            Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde;
          </p>
          <p>
            (3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art.9 Abs.2 lit.h
            und i sowie Art.9 Abs.3 DSGVO;
          </p>
          <p>
            (4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke
            oder für statistische Zwecke gem. Art.89 Abs.1 DSGVO, soweit das unter Abschnitt a) genannte Recht
            voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
            beeinträchtigt, oder
          </p>
          <p>
            (5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>
          <h4 className="my-2">5. Recht auf Unterrichtung</h4>

          <p>
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung uns gegenüber geltend
            gemacht, sind wir verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten
            offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung
            mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
            verbunden.
          </p>
          <p>
            Ihnen steht uns gegenüber das Recht zu, über diese Empfänger unterrichtet zu werden.
          </p>
          <h4 className="my-2">6. Recht auf Datenübertragbarkeit</h4>

          <p>
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in
            einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese
            Daten einem anderen Verantwortlichen ohne Behinderung zu übermitteln, sofern
          </p>
          <p>
            (1) die Verarbeitung auf einer Einwilligung gem. Art.6 Abs.1 lit.a DSGVO oder Art.9 Abs.2 lit.a DSGVO oder
            auf einem Vertrag gem. Art.6 Abs.1 lit.b DSGVO beruht und
          </p>
          <p>
            (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
          </p>
          <p>
            In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
            personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies
            technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
          </p>
          <p>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die
            Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die uns übertragen wurde.
          </p>
          <h4 className="my-2">7. Widerspruchsrecht</h4>

          <p>
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.6 Abs.1 lit.e oder f DSGVO
            erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
          </p>
          <p>
            Wir verarbeiten die Sie betreffenden personenbezogenen Daten dann nicht mehr, es sei denn, wir können
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
            überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>
          <p>
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie
            das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
            Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung
            in Verbindung steht.
          </p>
          <p>
            Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
            personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
          </p>
          <p>
            Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft –
            ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben,
            bei denen technische Spezifikationen verwendet werden.
          </p>
          <h4 className="my-2">8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h4>

          <p>
            Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den
            Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
            Verarbeitung nicht berührt.
          </p>
          <h4 className="my-2">9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h4>

          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich
            Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
            oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
          </p>
          <p>
            (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist,
          </p>
          <p>
            (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen wir unterliegen, zulässig ist
            und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer
            berechtigten Interessen enthalten oder
          </p>
          <p>
            (3) mit Ihrer ausdrücklichen Einwilligung erfolgt.
          </p>
          <p>
            Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art.9
            Abs.1 DSGVO beruhen, sofern nicht Art.9 Abs.2 lit.a oder g gilt und angemessene Maßnahmen zum Schutz der
            Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
          </p>
          <p>
            Hinsichtlich der in (1) und (3) genannten Fälle treffen wir angemessene Maßnahmen, um die Rechte und
            Freiheiten sowie Ihre berechtigten Interessen zu wahren.
          </p>
          <h4 className="my-2">10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>

          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das
            Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts,
            Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
          </p>
          <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den
            Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
            nach Art.78 DSGVO.
          </p>
        </div>
        <h4 className="mt-12">Verantwortlicher für die Datenverarbeitung:</h4>
        <p>Hochzehn GmbH & Co. KG</p>
        <p>Hafenweg 16</p>
        <p>48155 Münster</p>
        <p>Telefon: +49 (0) 251 149 823 30</p>
        <p>hello@hochzehn.com</p>
      </div>
    </Layout>
  );
}

export default Privacy;
